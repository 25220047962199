import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';

function HeroSection() {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        backgroundImage: 'url(./dimensions.jpeg)', // Replace with your image path        
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textAlign: 'center',
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
          backdropFilter: 'blur(1px)', // Blur effect
          zIndex: 1,
        },
      }}
    >
      <Box sx={{ position: 'relative', zIndex: 2 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Jake Bringetto
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Hello. Welcome to my personal website.
        </Typography>
        <ScrollLink to="education" smooth={true} duration={500} offset={15}>
          <Button
            variant="contained"
            sx={{
              mt: 2,
              backgroundColor: '#666666', // Custom background color
              color: '#ffffff', // Custom text color
              '&:hover': {
                backgroundColor: '#666666', // Hover background color
              },
              padding: '10px 20px',
              fontSize: '1rem',
              fontWeight: 'bold',
              borderRadius: '8px', // Rounded corners
            }}
          >
            Continue.
          </Button>
        </ScrollLink>
      </Box>
    </Box>
  );
}

export default HeroSection;
