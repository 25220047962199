import React from 'react';
import { Card, CardContent, Typography, Grid, Divider } from '@mui/material';
import { motion } from 'framer-motion';

const schools = [
  { 
    title: 'UC Berkeley', 
    description: 'B.S. Electrical Engineering and Computer Science',
    details: 'Regents Scholar, 2020-2024',
    coursework: [
        { title: 'Structure and Interpretation of Computer Programs', description: 'Introduction to CS, managing complexity  through abstraction.', image: './cs61a.jpeg' },
        { title: 'Data Structures', description: 'Software and machines from a programmer’s point of view.', image: './cs61b.jpeg' },
        { title: 'Computer Architecture', description: 'Translation of high-level programs into machine language, computer organization, caches, performance measurement, parallelism, CPU design.', image: 'cs61c.jpeg' },
        { title: 'Designing Information Devices and Systems I', description: 'The applications of linear algebra, exploring its impact on fields such as imaging, signals and systems, machine learning, and other modern areas.', image: './eecs16a.jpeg' },
        { title: 'Designing Information Devices and Systems II', description: 'Introduction to differential equations, the frequency domain, stability/controllability, linear algebra for machine learning.', image: './eecs16b.png' },
        { title: 'Discrete Mathematics and Probability Theory', description: 'Propositions/Proofs, mathematical induction, arithmetic algorithms, probability and probabilistic algorithms, diagonalization and computability.', image: './cs70.jpeg' },
        { title: 'Effective Algorithms and Intractable Problems', description: 'The design and analysis of graph algorithms, greedy algorithms, dynamic programming, linear programming, fast matrix multiplication, Fourier transforms, number theory, complexity, and NP-completeness.', image: './cs170.png' },
        { title: 'Probability and Random Processes', description: 'Fundamentals of probability, random processes, Markov Chains, Queueing, MLE/MAP, Kalman filtering, information theory, communication channels.', image: './eecs126.jpeg' },
        { title: 'Optimization Models in Engineering', description: 'Optimization models and their applications, ranging from machine learning and statistics to decision-making and control, with emphasis on numerically tractable problems.', image: './eecs127.jpeg' },
        { title: 'Introduction to Machine Learning', description: 'Regression, Gaussian discriminant analysis, decision trees, neural networks, boosting, density estimation, dimensionality reduction, clustering, learning theory.', image: './cs189.jpeg' },
        { title: 'Introduction to the Internet: Architecture and Protocols', description: 'The fundamental concepts of networking as embodied in the Internet.', image: './cs168.jpeg' },
        { title: 'Designing, Visualizing, and Understanding Deep Neural Networks', description: 'Defining, training and fine-tuning neural networks, contemporary models, meta-learning, generative models such as transformers and stable diffusion.', image: './cs182.jpeg' },
        { title: 'Operating Systems and Systems Programming', description: 'Networked and distributed systems, multiple-program systems (processes, interprocess communication, synchronization), memory allocation, resource allocation and scheduling, file systems, basic networking (sockets, layering, APIs, reliability).', image: './cs162.jpeg' },
        { title: 'Computer Security', description: 'Cryptography, including encryption, authentication, hash functions, cryptographic protocols, and applications. Operating system security, access control. Network security, firewalls, viruses, and worms.', image: './cs161.jpeg' },
        { title: 'Computational Models of Cognition', description: 'Models of human cognition - symbolic approaches, neural networks, probability and statistics.', image: './cogsci.jpeg' },
        { title: 'Swedish 1-4', description: 'Inte den mest relevanta på denna lista men... Svenska nivå B2 - arbetsförmåga', image: './swedish.png' },
    ]
  },
  { 
    title: 'Lund University', 
    description: 'Study abroad in Lund, Sweden',
    details: 'Spring 23',
    coursework: [
      { title: 'Artificial Intelligence', description: 'Intelligent agents, game programming, knowledge-based systems/representation, natural language processing.', image: './lundai.jpeg'},
      { title: 'Learning-Based Control', description: 'Fundamental theory and methodology for developing control laws based on measured input and output signal data.', image: './lundlc.png' },
    ]
  },
];

function Education() {
  return (
    <section id="education" style={{ paddingTop: '80px' }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Education
      </Typography>
      <Grid container direction="column" spacing={3}>
        {schools.map((school, index) => (
          <Grid item key={index}>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h3">
                    {school.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {school.description}
                  </Typography>
                  <Divider sx={{ margin: '10px 0' }} />
                  <Typography variant="body2" color="textSecondary">
                    {school.details}
                  </Typography>
                  <Typography variant="h6" component="h4" sx={{ marginTop: '10px' }}>
                    Coursework
                  </Typography>
                  <Grid container spacing={2}>
                    {school.coursework.map((course, idx) => (
                      <Grid item xs={12} sm={6} md={3} key={idx}>
                        <motion.div whileHover={{ scale: 1.1 }}>
                          <Card className="course-card">
                            <div className="course-image" style={{ backgroundImage: `url(${course.image})` }}></div>
                            <div className="course-overlay">
                              <Typography variant="subtitle1" className="course-title">
                                {course.title}
                              </Typography>
                              <Typography variant="body2" className="course-description">
                                {course.description}
                              </Typography>
                            </div>
                          </Card>
                        </motion.div>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </section>
  );
}

export default Education;