import React from 'react';
import { Box, Typography } from '@mui/material';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

function Resume() {
  return (
    <section id="resume" style={{ paddingTop: '80px' }}>
        <Box sx={{ paddingTop: '80px' }}>
            <Typography variant="h4" component="h2" gutterBottom>
                My Resume
            </Typography>
            <Box sx={{ height: '500px', position: 'relative' }}>
                <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
                <Viewer fileUrl="/curr-resume.pdf" />
                </Worker>
            </Box>
        </Box>
    </section>

  );
}

export default Resume;
