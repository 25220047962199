import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { motion } from 'framer-motion';

const jobs = [
  { 
    title: 'Meta', 
    description: 'Software Engineering Intern, May 2022 - August 2022',
    details: [
      'Designed data pipelines for ML-based ranking and recommendation systems.',
      'Engineered features for the ranking model, handling event logging and integration with a feature store.',
      'Enabled ranking in an unranked product area and created the production-ready execution graph.',
    ]
  },
  { 
    title: 'UC Berkeley', 
    description: 'Academic Student Employee for <a href="https://cs61a.org/" target="_blank" rel="noopener noreferrer">cs61a</a>.',
    details: [
      'Taught weekly sections and held regular office hours.',
      'Developed anti-cheat software that successfully identified and prevented academic dishonesty.',
    ]
  },
  { 
    title: 'Ayurdata', 
    description: 'Co-founder and VP of Products. Learn more about Ayurdata <a href="https://www.ayurdata.ai/" target="_blank" rel="noopener noreferrer">here</a>. Feel free to contact me for additional information!',
    details: [
      'Build a feature complete EHR (electronic health record) on top of the open source project <a href="https://openmrs.org/" target="_blank" rel="noopener noreferrer">openMRS</a>',
      'Built custom software for the openMRS project, such as <a href="https://www.npmjs.com/package/@ayurdata/esm-ayurdata-app" target="_blank" rel="noopener noreferrer">an npm package</a> with 60+ downloads',
      'Integrated the EHR with <a href="https://abdm.gov.in/" target="_blank" rel="noopener noreferrer">the Ayushman Digital Mission</a> for clinic to clinic medical record share built on patient consent',
      'Developed AI tools such as natural language document search, contextual patient summaries, and document pre-fill via speech transcription',
      'Backed by Microsoft for startups, GCP startup program, and Nvidia Inception - totaling 1 million+ compute credits',
    ]
  },
];

function Work() {
  return (
    <section id="work" style={{ paddingTop: '80px' }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Work Experience
      </Typography>
      <Grid container direction="column" spacing={3}>
        {jobs.map((job, index) => (
          <Grid item key={index}>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h3">
                    {job.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: job.description }}
                    sx={{ 
                      mb: 2,
                      '& a': { 
                        color: '#1976d2', 
                        '&:hover': {
                          color: '#0d47a1', 
                        },
                      },
                    }}
                  />
                  <Typography variant="h6" component="h4" sx={{ marginTop: '10px' }}>
                    Details
                  </Typography>
                  <ul style={{ paddingLeft: '20px', marginTop: '5px', marginBottom: '5px' }}>
                  {job.details.map((detail, idx) => (
                      <li key={idx}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="div"
                          dangerouslySetInnerHTML={{ __html: detail }}
                          sx={{ 
                            mb: 2,
                            '& a': { 
                              color: '#1976d2', 
                              '&:hover': {
                                color: '#0d47a1', 
                              },
                            },
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </section>
  );
}

export default Work;