import React from 'react';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Projects from './components/Projects';
import Work from './components/Work';
import Education from './components/Education';
import SkillBars from './components/SkillBars';
import Resume from './components/Resume';
import Footer from './components/Footer';
import PythonCompiler from './components/PythonCompiler';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Box sx={{ marginTop: '64px' }}>
        <Container>
          <main>
            <Education />
            <Work />
            <Projects />
            <Resume />
            <SkillBars />
            <PythonCompiler />
          </main>
        </Container>
        <Footer />
      </Box>
    </div>
  );
}

export default App;
