import React from 'react';
import { Box, Typography } from '@mui/material';

const skills = [
  { name: 'python', level: 95 },
  { name: 'C', level: 85 },
  { name: 'React', level: 85 },
  { name: 'SQL', level: 75 },
  { name: 'Java', level: 75 },
  { name: 'Go', level: 75},
];

function SkillBars() {
  return (
    <section id="skills" style={{ padding: '80px 0' }}>
      <Typography variant="h4" component="h2" gutterBottom>
        My Languages
      </Typography>
      {skills.map((skill, index) => (
        <Box key={index} sx={{ marginBottom: '16px' }}>
          <Typography variant="subtitle1" component="p" gutterBottom>
            {skill.name}
          </Typography>
          <Box sx={{ width: '100%', backgroundColor: '#e0e0e0' }}>
            <Box
              sx={{
                width: `${skill.level}%`,
                backgroundColor: '#3f51b5',
                color: 'white',
                textAlign: 'center',
                padding: '8px 0',
              }}
            >
              {skill.level}%
            </Box>
          </Box>
        </Box>
      ))}
    </section>
  );
}

export default SkillBars;
