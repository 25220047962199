import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { FaEnvelope, FaGithub, FaLinkedin } from 'react-icons/fa';
import { Link as ScrollLink } from 'react-scroll';

function Header() {
  return (
    <AppBar position="fixed" sx={{ background: 'transparent', boxShadow: 'none' }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h6" component="div" sx={{ color: '#000' }}>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <ScrollLink to="education" smooth={true} duration={500} offset={15}>
            <Typography sx={{ cursor: 'pointer', color: '#000' }}>Education</Typography>
          </ScrollLink>
          <ScrollLink to="work" smooth={true} duration={500} offset={15}>
            <Typography sx={{ cursor: 'pointer', color: '#000' }}>Work</Typography>
          </ScrollLink>
          <ScrollLink to="projects" smooth={true} duration={500} offset={15}>
            <Typography sx={{ cursor: 'pointer', color: '#000' }}>Projects</Typography>
          </ScrollLink>
          <ScrollLink to="resume" smooth={true} duration={500} offset={15}>
            <Typography sx={{ cursor: 'pointer', color: '#000' }}>Resume</Typography>
          </ScrollLink>
          <a href="https://github.com/jakeBringetto" target="_blank" rel="noopener noreferrer" style={{ color: '#000', display: 'flex', alignItems: 'center' }}>
            <FaGithub />
          </a>
          <a href="https://linkedin.com/in/jakebringetto" target="_blank" rel="noopener noreferrer" style={{ color: '#000', display: 'flex', alignItems: 'center' }}>
            <FaLinkedin />
          </a>
          <a href="mailto:jakebringetto@berkeley.edu" target="_blank" rel="noopener noreferrer" style={{ color: '#000', display: 'flex', alignItems: 'center' }}>
            <FaEnvelope />
          </a>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
