import React from 'react';
import { Card, CardContent, Typography, Grid, CardMedia } from '@mui/material';
import { motion } from 'framer-motion';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const projects = [
  { 
    title: 'Git Lite', 
    description: 'Java application with the functionality of the Git version control system Uses efficient data structures, the SHA-1 hash function, and graph traversal algorithms for file persistence, file serialization, branching, checkouts, merging, merge conflicts, and remote repositories. Contact me to view the source code.',
    videoUrl: '', // Example video URL
    imageUrl: 'java.jpeg' // Fallback image if no video is provided
  },
  { 
    title: 'Matricks', 
    description: 'Python library for efficient linear algebra operations. Matricks uses efficient algorithms, cache optimization, and both thread and data level parallelism to acheive 2000x speed-ups. The python-C interface makes easy-to-use python bindings to seamlessly invoke the C functions. Contact me to view the source code.',
    videoUrl: '', // No video for this project
    imageUrl: 'cpython.jpeg'
  },
  { 
    title: 'PintOS', 
    description: 'Simple operating system that can run user programs and invoke system calls for file operations and multiprocessing. It has support for running threads with a strict priority scheduler and priority donation. I implemented fast file system (FFS) with support for expansible files, buffer cache, and subdirectories. Contact me to view the source code.',
    videoUrl: '',
    imageUrl: 'peng.jpeg'
  },
  { 
    title: 'Spotify Bot', 
    description: 'Spotify bot for synching playlists, extracting song features to make datasets for ML, and playlist prediction for unadded songs. The video demo above shows the playlist guesser using a decision tree as the prediction model. Read more about the project <a href="https://github.com/jakeBringetto/spotify-sync-type-beat/blob/main/README.md" target="_blank" rel="noopener noreferrer">here.</a>',
    videoUrl: "spotify.mov",
    imageUrl: 'peng.jpeg'
  },
  { 
    title: 'Fire Bot', 
    description: 'Specialized chat-bot for fire department policies and procedures. The underlying model uses retrieval augmented generation (RAGs) via a pinecone vector database used to fetch context for GPT-4. Displayed with a simple UI using Python + Flask + React. Read more about the project <a href="https://github.com/jakeBringetto/fire-bot/blob/main/README.md" target="_blank" rel="noopener noreferrer">here.</a>',
    videoUrl: 'firebot.mov',
    imageUrl: 'peng.jpeg'
  },
  { 
    title: 'Vision Transformer for Ed', 
    description: 'I created this assignment to teach about vision transformers and optimizing their compute. The assignment/python notebook walks through multiple papers, a ViT implemented with JAX, and analysis of a linear-angular attention function. The pdf aboves shows solutions and the papers referenced.',
    pdfUrl: 'vision.pdf', 
    imageUrl: 'peng.jpeg' 
  },
];

function Projects() {
  return (
    <section id="projects" style={{ paddingTop: '80px' }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Projects
      </Typography>
      <Grid container spacing={3}>
        {projects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Card>
                {project.videoUrl ? (
                  <CardMedia
                    component="video"
                    controls
                    src={project.videoUrl}
                    title={project.title}
                    sx={{ height: 200 }}
                  />
                ) : project.pdfUrl ? (
                    <div style={{ height: 200, position: 'relative' }}>
                      <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
                        <Viewer fileUrl={project.pdfUrl} />
                      </Worker>
                      <a href={project.pdfUrl} target="_blank" rel="noopener noreferrer" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10 }}></a>
                    </div>
                  ) : (
                    <CardMedia
                      component="img"
                      src={project.imageUrl}
                      alt={project.title}
                      title={project.title}
                      sx={{ height: 200 }}
                    />
                )}
                <CardContent>
                  <Typography variant="h5" component="h3">
                    {project.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: project.description }}
                    sx={{ 
                      mb: 2,
                      '& a': { // Style the links
                        color: '#1976d2', // Custom link color
                        '&:hover': {
                          color: '#0d47a1', // Darken the link color on hover
                        },
                      },
                    }}
                  />
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </section>
  );
}

export default Projects;
