import React, { useState, useEffect, useRef } from 'react';
import Editor from '@monaco-editor/react';
import * as monaco from 'monaco-editor';

function PythonEditor() {
  const [code, setCode] = useState('');
  const [output, setOutput] = useState('');
  const editorRef = useRef(null);

  useEffect(() => {
    const loadPyodide = async () => {
      const pyodide = await window.loadPyodide({ indexURL: "https://cdn.jsdelivr.net/pyodide/v0.21.3/full/" });
      window.pyodide = pyodide;
    };
    loadPyodide();
  }, []);

  const runCode = async () => {
    if (window.pyodide) {
      try {
        let result = await window.pyodide.runPythonAsync(code);
        setOutput(result);
      } catch (err) {
        setOutput(err.toString());
      }
    } else {
      setOutput('Pyodide is still loading...');
    }
  };

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
  };

  return (
    <div>
      <h2>Python IDE using web assmebly - just for fun</h2>
      <Editor
        height="400px"
        defaultLanguage="python"
        defaultValue="# Write your Python code here"
        onChange={(value) => setCode(value)}
        onMount={handleEditorDidMount}
        theme="vs-dark"
      />
      <br />
      <button onClick={runCode}>Run</button>
      <h3>Output:</h3>
      <pre>{output}</pre>
    </div>
  );
}

export default PythonEditor;
